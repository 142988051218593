<template>
    <div v-if="hasDeliveredLoans" id="loan-delivered-detail">
        <!-- CARD DETALLE DE CRÉDITOS -->
        <vs-tabs v-if="isMounted && showCards" v-model="activeTab" class="tabs-shadow-none" id="loan-tabs">
            <vs-tab label="Global">
                <vx-card>
                    <h2 class="pl-2 mb-4">Mis créditos activos</h2>
                    <div class="vx-row">
                        <div class="vx-col sm:w-full md:w-1/4">
                            <div class="flex justify-left items-baseline mb-2 pl-2">
                                <vs-chip class="bg-rg-green-light bold text-md" :class="globalCreditStatus.color">{{
                                    globalCreditStatus.status }}</vs-chip>
                                <p class="clickable-img text-sm ml-2" @click.stop="showMasterLoanDetail = true"><u>Ver
                                        detalle</u></p>
                            </div>
                            <div v-if="globalCreditStatus.status != 'Al corriente'">
                                <p v-if="roleGeneralData.days" class="mb-2 pl-4"><span class="bold text-danger">{{
                                        roleGeneralData.days }}</span></p>
                                <p class="pl-4">Pago pendiente</p>
                                <vs-collapse>
                                    <vs-collapse-item>
                                        <div slot="header">
                                            <h2>{{ explicitMoneyFormat(duesList.total.due) }}</h2>
                                        </div>
                                        <ul>
                                            <li class="text-sm"><span class="bold">Amortización: </span> {{
                                                explicitMoneyFormat(duesList.total.amortization) }}</li>
                                            <li class="text-sm"><span class="bold">Intereses + IVA: </span> {{
                                                explicitMoneyFormat(duesList.total.interest + duesList.total.tax) }}
                                            </li>
                                            <li class="text-sm"><span class="bold">Comisiones + IVA: </span> {{
                                                explicitMoneyFormat(duesList.total.client_comission +
                                                duesList.total.client_comission_tax) }}</li>
                                            <li class="text-sm"><span class="bold">Penalización + IVA: </span> {{
                                                explicitMoneyFormat(duesList.total.penalty + duesList.total.penalty_tax)
                                                }}</li>
                                            <li class="text-sm"><span class="bold">Intereses moratorios + IVA: </span>
                                                {{ explicitMoneyFormat(duesList.total.moratorium_interest +
                                                duesList.total.moratorium_interest_tax) }}</li>
                                        </ul>
                                    </vs-collapse-item>
                                </vs-collapse>
                            </div>
                            <div v-else>
                                <div v-if="currentMasterPayment != null">
                                    <p class="pl-4">Próximo pago ({{
                                        constitutionPaymentDateFormat(currentMasterPayment.payment_date) }})</p>
                                    <vs-collapse>
                                        <vs-collapse-item>
                                            <div slot="header">
                                                <h2>{{ explicitMoneyFormat(currentMasterPayment.payment) }}</h2>
                                            </div>
                                            <ul>
                                                <li class="text-sm"><span class="bold">Amortización: </span> {{
                                                    explicitMoneyFormat(currentMasterPayment.amortization) }}</li>
                                                <li class="text-sm"><span class="bold">Intereses + IVA: </span> {{
                                                    explicitMoneyFormat(currentMasterPayment.interest +
                                                    currentMasterPayment.tax) }}</li>
                                                <li class="text-sm"><span class="bold">Comisiones + IVA: </span> {{
                                                    explicitMoneyFormat(currentMasterPayment.client_comission) }}</li>
                                                <li v-if="currentMasterPayment.with_due" class="text-sm"><span
                                                        class="bold">Intereses moratorios + IVA: </span> {{
                                                    explicitMoneyFormat(currentMasterPayment.moratorium_interest) }}
                                                </li>
                                            </ul>
                                        </vs-collapse-item>
                                    </vs-collapse>
                                </div>
                                <div v-else>
                                    <h6>Sin información del pago.</h6>
                                </div>
                            </div>
                            <div v-if="!hasDirectBilling" class="flex justify-left mt-2 pl-2">
                                <vs-button size="small" color="dark" type="border" class="mr-2"
                                    @click.stop="showDirectBillingPrompt = true">Domiciliar</vs-button>
                                <vs-button size="small" color="dark"
                                    @click.stop="showPaymentDetailPrompt = true">Pagar</vs-button>
                            </div>
                            <div v-else class="mt-2 pl-2">
                                <p class="black mb-2"><span class="bold">Pago domiciliado</span> {{
                                    user.direct_billing.bank }} <span class="bold">{{ user.direct_billing.clabe
                                        }}</span></p>
                                <p class="black underline text-sm cursor-pointer"
                                    @click.stop="showDirectBillingPrompt = true">Cambiar cuenta</p>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </vs-tab>
            <vs-tab @click.stop="getLoanPaymentData(loan.id)" :key="index" v-for="(loan, index) in deliveredLoans"
                :label="`${loan.alias} (${loan.id})`">
                <vx-card v-if="currentLoan != null">
                    <h2 class="pl-2 mb-4">{{ loan.alias }} <span class="font-regular">({{ loan.id }})</span></h2>
                    <div class="vx-row">
                        <div class="vx-col sm:w-full md:w-1/4">
                            <div class="flex justify-left items-baseline mb-2 pl-2">
                                <vs-chip class="bold text-md"
                                    :class="!isLoanOnTime ? 'bg-chip-danger-light' : 'bg-rg-green-light'">{{
                                    currentLoan.loan_status }}</vs-chip>
                                <p class="clickable-img text-sm ml-2"
                                    @click.stop="setDetailSingleLoanPayment(`${loan.alias} (${loan.id})`)"><u>Ver
                                        detalle</u></p>
                            </div>
                            <div v-if="hasDuesSingleLoan">
                                <p v-if="!isLoanOnTime" class="mb-2 pl-4">Días de atraso: <span
                                        class="bold text-danger">{{
                                            calculateDaysDiff(currentLoan.dues_data.payment_date) }}</span></p>
                                <p class="pl-4">Pago pendiente</p>
                                <vs-collapse>
                                    <vs-collapse-item>
                                        <div slot="header">
                                            <h2>{{ explicitMoneyFormat(currentLoan.dues_data.due) }}</h2>
                                        </div>
                                        <ul>
                                            <li class="text-sm"><span class="bold">Amortización: </span> {{
                                                explicitMoneyFormat(currentLoan.dues_data.amortization) }}</li>
                                            <li class="text-sm"><span class="bold">Intereses + IVA: </span> {{
                                                explicitMoneyFormat(currentLoan.dues_data.interest +
                                                currentLoan.dues_data.tax) }}</li>
                                            <li class="text-sm"><span class="bold">Comisiones + IVA: </span> {{
                                                explicitMoneyFormat(currentLoan.dues_data.client_comission +
                                                currentLoan.dues_data.client_comission_tax) }}</li>
                                            <li class="text-sm"><span class="bold">Penalización + IVA: </span> {{
                                                explicitMoneyFormat(currentLoan.dues_data.penalty +
                                                currentLoan.dues_data.penalty_tax) }}</li>
                                            <li class="text-sm"><span class="bold">Intereses moratorios + IVA: </span>
                                                {{ explicitMoneyFormat(currentLoan.dues_data.moratorium_interest +
                                                currentLoan.dues_data.moratorium_interest_tax) }}</li>
                                        </ul>
                                    </vs-collapse-item>
                                </vs-collapse>
                            </div>
                            <div v-else>
                                <div v-if="currentLoanNextPayment != null">
                                    <p v-if="!isLoanOnTime" class="mb-2 pl-4">Días de atraso: <span
                                            class="bold text-danger">{{
                                                calculateDaysDiff(currentLoanNextPayment.payment_date) }}</span></p>
                                    <p class="pl-4">Próximo pago ({{
                                        constitutionPaymentDateFormat(currentLoanNextPayment.payment_date) }})</p>
                                    <vs-collapse>
                                        <vs-collapse-item>
                                            <div slot="header">
                                                <h2>{{ explicitMoneyFormat(currentLoanNextPayment.payment) }}</h2>
                                            </div>
                                            <ul>
                                                <li class="text-sm"><span class="bold">Amortización: </span> {{
                                                    explicitMoneyFormat(currentLoanNextPayment.amortization) }}</li>
                                                <li class="text-sm"><span class="bold">Intereses + IVA: </span> {{
                                                    explicitMoneyFormat(currentLoanNextPayment.interest +
                                                    currentLoanNextPayment.tax) }}</li>
                                                <li class="text-sm"><span class="bold">Comisiones + IVA: </span> {{
                                                    explicitMoneyFormat(currentLoanNextPayment.client_comission +
                                                    currentLoanNextPayment.client_commission_tax) }}</li>
                                                <li v-if="currentLoanNextPayment.with_due" class="text-sm"><span
                                                        class="bold">Intereses moratorios + IVA: </span> {{
                                                    explicitMoneyFormat(currentLoanNextPayment.moratorium_interest) }}
                                                </li>
                                            </ul>
                                        </vs-collapse-item>
                                    </vs-collapse>
                                </div>
                                <div v-else>
                                    <h6>Sin información del pago.</h6>
                                </div>
                            </div>
                            <div v-if="!hasDirectBilling" class="flex justify-left mt-2 pl-2">
                                <vs-button size="small" color="dark" type="border" class="mr-2"
                                    @click.stop="showDirectBillingPrompt = true">Domiciliar</vs-button>
                                <vs-button size="small" color="dark"
                                    @click.stop="showPaymentDetailPrompt = true">Pagar</vs-button>
                            </div>
                            <div v-else class="mt-2 pl-2">
                                <p class="black mb-2"><span class="bold">Pago domiciliado</span> {{
                                    user.direct_billing.bank }} <span class="bold">{{ user.direct_billing.clabe
                                        }}</span></p>
                                <p class="black underline text-sm cursor-pointer"
                                    @click.stop="showDirectBillingPrompt = true">Cambiar cuenta</p>
                            </div>
                        </div>
                    </div>

                </vx-card>
            </vs-tab>
        </vs-tabs>

        <!-- DETALLE CONSOLIDADO -->
        <vs-popup v-if="isMounted" title="Detalle de Mis créditos activos" :active.sync="showMasterLoanDetail"
            class="md-popup header-primary">
            <ul class="p-3">
                <li class="mb-3">
                    <div class="flex">
                        <div class="bullet bg-primary mr-2 mt-2 mb-1"></div>
                        Pagado: <br>
                    </div>
                    <h3 class="ml-2">{{ explicitMoneyFormat(master.total.payed) }}</h3>
                </li>
                <li class="mb-3">
                    <div class="flex">
                        <div class="bullet bg-danger mr-2 mt-2 mb-1"></div>
                        Atrasado: <br>
                    </div>
                    <h3 class="ml-2">{{ explicitMoneyFormat(master.total.dues) }}</h3>
                </li>
                <li class="mb-3">
                    <div class="flex">
                        <div class="bullet bg-grey mr-2 mt-2 mb-1"></div>
                        Por pagar: <br>
                    </div>
                    <h3 class="ml-2">{{ explicitMoneyFormat(master.total.payment - master.total.payed) }}</h3>
                </li>
            </ul>
            <p class="clickable-img text-sm ml-2 black" @click.stop="showMasterLoanPayments = true"><u>Ver plan de
                    pagos</u></p>
            <vs-popup v-if="isMounted" title="Consolidado de Mis créditos activos" :active.sync="showMasterLoanPayments"
                class="extended-popup header-primary">
                <ApplicantPaymentsPlan projectId="0" :masterGlobal="true" />
            </vs-popup>
        </vs-popup>

        <!-- DETALLE POR PROYECTO -->
        <vs-popup v-if="showSingleLoanDetail" :title="`Detalle de ${singleLoanTitle}`"
            :active.sync="showSingleLoanDetail" class="md-popup header-primary">
            <ul class="p-3">
                <li class="mb-3">
                    <div class="flex">
                        <div class="bullet bg-primary mr-2 mt-2 mb-1"></div>
                        Pagado: <br>
                    </div>
                    <h3 class="ml-2">{{ explicitMoneyFormat(currentLoan.totalMonthlyPayments.payed) }}</h3>
                </li>
                <li class="mb-3">
                    <div class="flex">
                        <div class="bullet bg-danger mr-2 mt-2 mb-1"></div>
                        Atrasado: <br>
                    </div>
                    <h3 class="ml-2">{{ explicitMoneyFormat(currentLoan.dues) }}</h3>
                </li>
                <li class="mb-3">
                    <div class="flex">
                        <div class="bullet bg-grey mr-2 mt-2 mb-1"></div>
                        Por pagar: <br>
                    </div>
                    <h3 class="ml-2">{{ explicitMoneyFormat(currentLoan.totalMonthlyPayments.payment -
                        currentLoan.totalMonthlyPayments.payed) }}</h3>
                </li>
            </ul>
            <p class="clickable-img text-sm ml-2 black" @click.stop="showSingleLoanPaymentDetail = true"><u>Ver plan de
                    pagos</u></p>
            <vs-popup v-if="isMounted" :title="`Consolidado de ${singleLoanTitle}`"
                :active.sync="showSingleLoanPaymentDetail" class="extended-popup header-primary">
                <ApplicantPaymentsPlan v-if="showSingleLoanPaymentDetail" :projectId="currentLoan.project_id" />
            </vs-popup>
        </vs-popup>

        <!-- ABONAR -->
        <vs-popup v-if="isMounted" :active.sync="showPaymentDetailPrompt" title="Abonar"
            class="md-popup header-primary">
            <PaymentInformation />
        </vs-popup>

        <!-- DOMICILIAR -->
        <vs-popup v-if="isMounted" :active.sync="showDirectBillingPrompt" title="Activa tu pago automático"
            class="md-popup header-primary">
            <div class="m-4 black">
                <h5 class="mt-2 mb-4 black bold">Fechas de corte</h5>
                <p class="mb-4">
                    El pago de tus créditos se hará automáticamente las siguientes fechas:
                </p>
                <ul class="mb-base">
                    <li class="mb-2" v-for="(loan, index) in allLoansPaymentData.allMasterTables">
                        <p>{{ loan.alias }} {{ loan.project_id }} | <span class="bold">Día {{
                            getDay(loan.monthlyPayments[0].payment_date) }}</span></p>
                    </li>
                </ul>
                <p class="mb-base">
                    Es posible que el cargo demore un par de días en verse reflejado. <br>Asegúrate de contar con los
                    fondos suficientes en tu cuenta.
                </p>
                <vs-select v-if="getBankAccountsItems.length > 0" class="sm:w-full md:w-3/4 lg:w-3/4 mb-2"
                    label="Elige tu cuenta" v-model.lazy="bankAccountSelectedId" name="bank_accounts"
                    v-validate="'required'" :danger="hasError('bank_accounts')"
                    :danger-text="errorText('bank_accounts')" :success="isSuccess('bank_accounts')"
                    placeholder="Seleccione una opción">
                    <vs-select-item :key="index" :value="item.value" :text="item.text"
                        v-for="(item, index) in getBankAccountsItems" class="w-full" />
                </vs-select>

                <p class="text-sm cursor-pointer mb-base" @click.stop="showCreateBankAccountPrompt = true">+ Agregar
                    cuenta</p>

                <vs-alert v-if="!canDirectBilling" icon-pack="feather" icon="icon-info" class="h-full my-4 mb-base"
                    color="#FFEE8D" active="true">
                    <span class="text-sm black">Por el momento no contamos con un convenio con esa institución
                        financiera. Prueba con otra cuenta o <b>registra una cuenta adicional</b>. </span>
                </vs-alert>

                <vs-button :disabled="bankAccountSelectedId == null || !canDirectBilling" color="dark"
                    @click.stop="showDirectBillingContractPrompt = true">Continuar</vs-button>

                <!-- REVISIÓN DE CONTRATO  -->
                <vs-popup id="contractPopup" v-if="isMounted" :title="`Activa tu pago automático`"
                    :active.sync="showDirectBillingContractPrompt" class="extended-popup header-primary pb-0">
                    <div>
                        <latest-direct-billing-acceptance-contract v-if="getBankAccountSelected"
                            :bank-account="getBankAccountSelected" user-role="applicant" />
                    </div>
                    <section class="contract-footer p-3">
                        <vs-button color="dark" @click.stop="acceptDirectBilling">Aceptar</vs-button>
                    </section>
                </vs-popup>

                <!-- CREAR CUENTA DE BANCO -->
                <vs-popup :active.sync="showCreateBankAccountPrompt" title="Registra una cuenta"
                    class="md-popup header-primary">
                    <div class="p-4">
                        <p class="mb-4">
                            Da de alta una cuenta bancaria a tu nombre para automatizar tus pagos.
                            <br>Haremos una transferencia de $0.01 para confirmar que la cuenta es tuya.
                        </p>
                        <div class="vx-row">
                            <div class="vx-col sm:w-full md:w-1/2">
                                <vs-input class="w-full" label="CLABE" name="clabe" v-validate="'required'"
                                    v-model.trim="clabe" :danger="hasError('clabe')" :danger-text="errorText('clabe')"
                                    :success="isSuccess('clabe')" icon-pack="feather" />
                            </div>
                            <vs-alert v-if="errorMssg" icon-pack="feather" style="height:auto"
                                icon="icon-alert-triangle" class="mb-3 mt-4 w-full ml-4 mr-4" color="danger">
                                <span>{{ errorMssg }}</span>
                            </vs-alert>
                        </div>
                        <vs-button :disabled="clabe == null" color="dark" @click.stop="saveBankAccount"
                            class="mt-4">Registrar</vs-button>
                    </div>
                </vs-popup>
            </div>
        </vs-popup>


        <!-- Componente para obtener la geolocalización -->
        <rg-geolocator ref="rgGeolocator" />

    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from '@mixins/dateHelper';
import formatHelper from '@mixins/formatHelper';
import ApplicantPaymentsPlan from '../../../components/applicant/projects/detail/ApplicantPaymentsPlan.vue';
import applicantInfoHelper from '@/helpers/applicantInfoHelper';
import LatestDirectBillingAcceptanceContract from "@views/share/contracts/DirectBillingContract/LatestDirectBillingAcceptanceContract.vue";
import PaymentInformation from "@views/applicant/onboarding/components/loan-payments/PaymentInformation.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
    name: "LoanDeliveredDetail",
    mixins: [dateHelper, formatHelper, applicantInfoHelper, inputHelper],
    components: {
        ApplicantPaymentsPlan,
        LatestDirectBillingAcceptanceContract,
        PaymentInformation
    },
    props: {
        showCards: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        contractAcceptDirectBillingType: 19,
        minScreen: 1100,
        activeTab: 0,
        loans: [],
        deliveredLoans: [],
        isMounted: false,
        currentLoan: null,
        currentLoanNextPayment: null,
        master: null,
        currentDate: new Date,
        currentMasterPayment: null,
        showMasterLoanDetail: false,
        showMasterLoanPayments: false,
        allLoansPaymentData: null,
        showSingleLoanDetail: false,
        showSingleLoanPaymentDetail: false,
        singleLoanTitle: "",
        showPaymentDetailPrompt: false,
        applicantPaymentInfo: null,
        showDirectBillingPrompt: false,
        showDirectBillingContractPrompt: false,
        applicantBankAccounts: [],
        bankAccountSelectedId: null,
        showCreateBankAccountPrompt: false,
        clabe: null,
        errorMssg: null,
        duesList: null
    }),
    async mounted() {
        this.isMounted = false;
        await this.showLoading(true);
        this.setCurrentDate();
        await this.getLoans();
        this.applicantPaymentInfo = await this.getApplicantPaymentInfo(this.ApplicantId);
        this.applicantBankAccounts = await this.getUserAccounts();
        if(this.hasDeliveredLoans) {
            // se pide la geolocalizacion para la operacion
            await this.$refs.rgGeolocator.askForGeolocation();
        }
        this.isMounted = true;
        await this.showLoading(false);
    },
    computed: {
        ...mapState("auth", ["roleGeneralData"]),
        isSmallerScreen() {
            return this.$store.state.windowWidth < this.minScreen;
        },
        hasDeliveredLoans() {
            return this.deliveredLoans.length > 0;
        },
        isLoanOnTime() {
            return this.currentLoan.loan_status === 'Al corriente' || this.currentLoan.loan_status === 'Liquidado';
        },
        globalCreditStatus() {
            const data = {
                status: "Al corriente",
                color: "bg-rg-green-light"
            }
            if (this.isMounted) {
                if (this.allLoansPaymentData != null) {
                    const loans = this.allLoansPaymentData.allMasterTables;
                    for (let index = 0; index < loans.length; index++) {
                        const loan = loans[index];
                        if (loan.loan_status != 'Al corriente' && loan.loan_status != 'Liquidado') {
                            data.status = loan.loan_status;
                            data.color = "bg-chip-danger-light";
                        }
                    }
                }
            }
            return data;
        },
        getBankAccountsItems() {
            if (this.isMounted) {
                const items = [];
                this.applicantBankAccounts.forEach(item => {
                    if (item.verified == "verified") {
                        items.push({
                            value: item.id,
                            text: `${item.bank.short_name} - ${item.clabe}`
                        });
                    }
                });
                return items;
            } else {
                return [];
            }
        },
        getBankAccountSelected() {
            return this.applicantBankAccounts.find(x => x.id === this.bankAccountSelectedId);
        },
        canDirectBilling() {
            if (this.getBankAccountSelected == null) {
                return true;
            }
            return this.getBankAccountSelected.bank.direct_billing_acceptance == 1;
        },
        hasDirectBilling() {
            return this.user.payment_method == 3;
        },
        hasDuesSingleLoan() {
            return this.currentLoan.dues_data != null;
        }
    },
    methods: {
        setCurrentDate() {
            let m = this.currentDate.getMonth() + 1;
            const y = this.currentDate.getFullYear().toString();
            if (m < 10) {
                m = `0${m}`;
            }
            this.currentDate = `${y}-${m}`
        },
        async getLoans() {
            try {
                const res = await axios.get(`api/loan-onboarding/get/${this.ApplicantId}/loans`);
                this.loans = res.data.data;
                this.deliveredLoans = res.data.loans;
                if (this.hasDeliveredLoans) {
                    await this.getAllPayments(this.deliveredLoans[0].id);
                    await this.getPaymentsByLoan(this.deliveredLoans[0].id);
                    await this.getDues(this.deliveredLoans[0].id);
                }
                this.isMounted = true;
            } catch (error) {
                console.log(error);
                this.failedOperationNotif("Algo salió mal.");
            }
        },
        async getPaymentsByLoan(loanId) {
            this.allLoansPaymentData = null;
            const res = await axios.get(`api/cobranza/proyectos/${loanId}/clientProjectsMaster`);
            this.allLoansPaymentData = res.data;
        },
        async getAllPayments(loanId) {
            const res = await axios.get(`api/cobranza/proyectos/${loanId}/master`);
            this.master = res.data;
            this.getNextPayment(true);
        },
        async getDues(loanId) {
            const res = await axios.get(`api/cobranza/adeudo/${loanId}`);
            this.duesList = res.data;
        },
        getNextPayment(isMaster) {
            // Sí es master usa la tabla consolidada
            const currentDate = moment().format("YYYY-MM-DD");
            if (isMaster) {
                const paymentTable = this.master.master
                // Se busca el pago mayo al día de hoy y que no esté pagado para obtener el siguiente pago.
                // const payment = paymentTable.filter(x => ((this.isAfter(x.payment_date, currentDate) || x.payment_date == currentDate) && x.paid == 0));
                const payment = paymentTable.filter(function (x) {
                    return ((x.payment_date == currentDate || !moment().isAfter(x.payment_date)) && x.paid == 0);
                });
                this.currentMasterPayment = payment.length > 0 ? payment[0] : null;
            } else {
                // Se debe obtener el pago del proyecto.
                this.currentLoanNextPayment = null;
                const paymentTable = this.currentLoan.monthlyPayments;
                // const payment = paymentTable.filter(x => ((this.isAfter(x.payment_date, currentDate) || x.payment_date == currentDate) && x.paid == 0));
                const payment = paymentTable.filter(function (x) {
                    return ((x.payment_date == currentDate || !moment().isAfter(x.payment_date)) && x.paid == 0);
                });

                this.currentLoanNextPayment = payment.length > 0 ? payment[0] : null;
            }

        },
        async acceptDirectBilling() {
            if (!this.canDirectBilling) {
                this.errorNotifDialog('Cuenta no soportada', "La cuenta de banco que indicas no esta habilitada para poder realizar domiciliación.");
                return false;
            }
            this.showLoading(true, "Guardando información...");
            try {
                let payload = {
                    geolocation: this.CurrentGeolocationData,
                    contractType: this.contractAcceptDirectBillingType,
                    lowerPeriodicity: this.user.lower_periodicity,
                    bankAccountId: this.bankAccountSelectedId
                };
                await axios.post("/api/v1/applicant/" + this.ApplicantId + "/acceptDirectBilling", payload);
                await this.getUserInfo(this.UserRole);
                this.showLoading(false);
                this.showDirectBillingContractPrompt = false;
                this.showDirectBillingPrompt = false;
                this.$vs.dialog({
                    color: 'success',
                    title: 'Pago automático activado',
                    text: `¡Listo! El pago de tu(s) crédito(s) se realizará automáticamente cada mes en tu fecha de corte.`,
                    acceptText: "Aceptar",
                    cancelText: 'Cerrar',
                });
            } catch (error) {
                this.showLoading(false);
                if (typeof error.response.data.message !== 'undefined') {
                    this.errorNotifDialog('Operación fallida', error.response.data.message);
                } else {
                    this.failedOperationNotif();
                }
            }
        },
        async saveBankAccount() {
            this.errorMssg = null;
            // if (!(await this.validateForm())) {
            //     return;
            // }
            if (this.clabe == null) {
                this.errorMssg = "Debes ingresar una CLABE";
                return;
            }

            if (this.clabe.length != 18) {
                this.errorMssg = "La CLABE debe tener 18 dígitos";
                return;
            }

            this.showLoading(true, "Guardando información...");
            try {
                let payload = {
                    clabe: this.clabe,
                    bank_account_id: null
                };
                const res = await axios.post(
                    `/api/loan-onboarding/post/${this.UserId}/update-bankaccount`,
                    payload
                );

                if (res.status == 200) {
                    this.showCreateBankAccountPrompt = false;
                    this.applicantBankAccounts = await this.getUserAccounts();
                    this.$vs.dialog({
                        color: 'success',
                        title: 'Cuenta registrada',
                        text: `Estamos verificando tu cuenta. Puede demorar hasta 24 horas en realizarse el proceso, te notificaremos cuando esté listo.`,
                        acceptText: "Aceptar",
                        cancelText: 'Cerrar',
                    });
                } else {
                    this.errorMssg = res.data;
                }
                this.showLoading(false);
            } catch (e) {
                this.showLoading(false);
                console.log(e);
                this.errorMssg = e;
            }
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        async getLoanPaymentData(loanId) {
            this.currentLoan = this.allLoansPaymentData.allMasterTables.find(x => x.project_id === loanId);
            this.sumProjectDues(loanId);
            this.getNextPayment(false);
        },
        sumProjectDues(loanId) 
        {
            const dues = this.duesList.dues.filter(x => x.project_id === loanId);
            console.log(dues);
            if(dues.length > 0) {
                this.currentLoan.dues_data;
                const duesData = {
                    payment_date : dues[0].payment_date,
                    due : 0,
                    amortization : 0,
                    interest : 0,
                    tax : 0,
                    client_comission : 0,
                    client_comission_tax : 0,
                    penalty : 0,
                    penalty_tax : 0,
                    moratorium_interest : 0,
                    moratorium_interest_tax : 0
                };
                // Tomar la fecha de pago del primer atraso 
                dues.forEach(due => {
                    // duesData.payment_date = due.payment_date;
                    duesData.due += due.due;
                    duesData.amortization += due.amortization;
                    duesData.interest += due.interest;
                    duesData.tax += due.tax;
                    duesData.client_comission += due.client_comission;
                    duesData.client_comission_tax += due.client_comission_tax;
                    duesData.penalty += due.penalty;
                    duesData.penalty_tax += due.penalty_tax;
                    duesData.moratorium_interest += due.moratorium_interest;
                    duesData.moratorium_interest_tax += due.moratorium_interest_tax;
                    
                });
                this.currentLoan.dues_data = duesData;
            } else {
                this.currentLoan.dues_data = null;
            }
            
        },
        setDetailSingleLoanPayment(alias) {
            this.singleLoanTitle = alias;
            this.showSingleLoanDetail = true;
        },
        copyToClipboard(element) {
            // Get the text field
            const copyText = document.getElementById(element);
            const textContent = copyText.textContent;

            // Copy the text inside the text field
            navigator.clipboard.writeText(textContent);

            // Alert the copied text
            // alert("Copied the text: " + textContent);
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return (ff && ff.required && ff.valid);
        },
    }
}
</script>

<style>
#contractPopup .vs-popup--content {
    padding-bottom: 0;
}

.contract-footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
}

.con-vs-dialog {
    z-index: 99999 !important;
}
</style>