<template>
    <div id="PaymentInformation" class="vx-card p-4">
        <div class="ml-4 m-4">
            <p class="mt-4 black mb-base">Registra tu CLABE personalizada en la app de tu banco <br>y transfiere cuando
                lo desees.</p>
            <ul class="black">
                <li class="mb-base">
                    <p class="mb-2 bold">Cuenta CLABE</p>
                    <div class="flex">
                        <p id="cuentaClabe">{{ applicantPaymentInfo.clabe_stp }}</p>
                        <feather-icon @click="copyToClipboard('cuentaClabe')" icon="CopyIcon"
                            svgClasses="w-5 h-5 ml-4 text-grey" class="cursor-pointer grey" />
                        <span v-show="showCopy[0]" id="cuentaClabeSpan" class="text-sm ml-4">- Copiado</span>
                    </div>
                </li>
                <li class="mb-base">
                    <p class="mb-2 bold">Banco</p>
                    <p>STP (Sistema de Transferencias y Pagos)</p>
                </li>
                <li class="mb-base">
                    <p class="mb-2 bold">Beneficiario</p>
                    <div class="flex mb-base">
                        <p id="beneficiario">RedGirasol</p>
                        <feather-icon @click="copyToClipboard('beneficiario')" icon="CopyIcon"
                            svgClasses="w-5 h-5 ml-4 text-grey" class="cursor-pointer grey" />
                        <span v-show="showCopy[1]" id="beneficiarioSpan" class="text-sm ml-4">- Copiado</span>
                    </div>
                    <p class="mb-2 bold">RFC</p>
                    <div class="flex">
                        <p id="rgTax">RGI160414TR9</p>
                        <feather-icon @click="copyToClipboard('rgTax')" icon="CopyIcon"
                            svgClasses="w-5 h-5 ml-4 text-grey" class="cursor-pointer grey" />
                            <span v-show="showCopy[2]" id="rgTaxSpan" class="text-sm ml-4">- Copiado</span>
                    </div>
                </li>
            </ul>
            <vs-alert icon-pack="feather" icon="icon-info" class="h-full my-4 mb-base" color="#C2C2C2">
                <p class="black">Solo podrás abonar desde una cuenta que esté a tu nombre. Si el nombre de la
                    cuenta no coincide, los fondos serán devueltos a la cuenta de origen. </p>
            </vs-alert>
        </div>
    </div>
</template>

<script>
import applicantInfoHelper from '@/helpers/applicantInfoHelper';
export default {
    name: "PaymentInformation",
    mixins: [applicantInfoHelper],
    data: () => ({
        applicantPaymentInfo: null,
        showCopy: [false, false, false]
    }),
    async mounted() {
        this.applicantPaymentInfo = await this.getApplicantPaymentInfo(this.ApplicantId);
    },
    methods: {
        copyToClipboard(element) {
            // Get the text field
            const copyText = document.getElementById(element);
            const textContent = copyText.textContent;

            // Copy the text inside the text field
            navigator.clipboard.writeText(textContent);

            // Show the copied text
            const index = element === 'cuentaClabe' ? 0 : element === 'beneficiario' ? 1 : 2;
            this.showCopy[index] = true;
            setTimeout(() => {
                this.showCopy[index] = false;
                this.$forceUpdate();
            }, 2500);
            this.$forceUpdate();
        },
    }
}
</script>

<style>
.display-none {
    display: none;
}   
.display-block {
    display: block !important;
}   
</style>